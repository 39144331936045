<template>
  <div class="device">
    <div class="wrap_box">
      <div class="header">
        <div class="title">
          <div class="index">
            治疗设备
          </div>
          <div class="sn">
            SN:{{ dataObject.sn }}
          </div>
        </div>
        <div class="power">
          <echart
            :config="echartConfigPower"
            :option="echartOptionPower"
            v-if="echartOptionPower"
          />
        </div>
      </div>
      <div class="contant">
        <div class="intro">
          <div class="base_info">
            <div
              class="user"
              :class="{'smallSize': dataObject.producerealname && dataObject.producerealname.length > 6 }"
            >
              <span :class="['iconfont','icon-travel',isMan(dataObject.userSex) ? 'man' : 'woman']" />
              <span>{{ dataObject.userrealname }}</span>
              <span class="iconfont icon-yisheng" />
              <span>{{ dataObject.producerealname }}</span>
            </div>
            <div class="creatDate">
              开方时间：{{ dataObject.recipecreatedate.split(' ')[0] }}
            </div>
            <div class="step">
              <!-- <span>治疗计划:{{ dataObject.workoutname }}</span> -->
              <span>治疗阶段:{{ blerunningstate.numPhase }}/{{
                blerunningstate.allStep
              }}</span>
            </div>
            <div class="time">
              <echart
                :config="echartConfigTime"
                :option="echartOptionTime"
                v-if="echartOptionTime"
              />
            </div>
          </div>

          <div class="body">
            <div
              class="scan"
              :style="`animation-duration: ${5+dataObject.sn.slice(-1)/10}s`"
            />
          </div>
        </div>
        <div class="current">
          <div class="left">
            <echart
              :config="echartConfigLeft"
              :option="echartOptionLeft"
              v-if="echartOptionLeft"
            />
            <div class="value">
              左:{{ blerunningstate.intensityCHL>blerunningstate.maxLeft?blerunningstate.maxLeft:blerunningstate.intensityCHL }}/{{
                blerunningstate.maxLeft
              }}mA
            </div>
          </div>
          <div class="right">
            <echart
              :config="echartConfigRight"
              :option="echartOptionRight"
              v-if="echartOptionRight"
            />
            <div class="value">
              右:{{ blerunningstate.intensityCHR>blerunningstate.maxRight?blerunningstate.maxRight:blerunningstate.intensityCHR }}/{{
                blerunningstate.maxRight
              }}mA
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echart from '@/libs/components/echart/echart'
import json from './json'
export default {
  name: 'Device',
  components: {
    echart
  },
  props: {
    dataObject: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    const random = window.X.libs.data.random(4)
    return {
      blerunningstate: {},
      echartDataPower: [],
      echartOptionPower: '',
      echartConfigPower: {
        id: 'power' + random,
        setStyle: { width: '2.625rem', height: '1.25rem' }
      },
      echartDataLeft: [],
      echartOptionLeft: '',
      echartConfigLeft: {
        id: 'left' + random,
        setStyle: { width: '12rem', height: '1.5rem', border: '3px solid #fff' }
      },
      echartDataRight: [],
      echartOptionRight: '',
      echartConfigRight: {
        id: 'right' + random,
        setStyle: { width: '12rem', height: '1.5rem', border: '3px solid #fff' }
      },
      echartOptionTime: '',
      echartConfigTime: {
        id: 'time' + random,
        setStyle: { width: '8rem', height: '8rem' }
      }
    }
  },
  watch: {
    dataObject: {
      handler () {
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.blerunningstate = this.dataObject.blerunningstate ? JSON.parse(this.dataObject.blerunningstate) : { bat: 0, intensityCHL: 0, intensityCHR: 0, userSet_intensityCHL: 0, userSet_intensityCHR: 0, remainingTime: 0, }
      if (this.blerunningstate.phaseModuleList) {
        this.blerunningstate.allStep = this.blerunningstate.phaseModuleList.length
        let nowStep = Number(this.blerunningstate.numPhase) - 1
        this.blerunningstate.maxRight = this.blerunningstate.phaseModuleList[nowStep].userSetRight / 10
        this.blerunningstate.maxLeft = this.blerunningstate.phaseModuleList[nowStep].userSetleft / 10
      } else this.blerunningstate.allStep = 1
      let _CHL = this.blerunningstate.intensityCHL / 10
      let _CHR = this.blerunningstate.intensityCHR / 10
      if (this.$route.query.demo === 'Y') {
        _CHL = Math.abs(parseInt((_CHL + json.setRandom()) % 10))
        _CHR = Math.abs(parseInt((_CHR + json.setRandom()) % 10))
      }
      this.blerunningstate.intensityCHL = _CHL
      this.blerunningstate.intensityCHR = _CHR
      const px = window.innerWidth / 120 / 16
      this.echartOptionPower = this.buildOptionPower(
        this.blerunningstate.bat,
        px
      )
      this.echartOptionLeft = this.buildOptionCurrent(
        _CHL,
        this.blerunningstate.maxLeft,
        '#32BEAC',
        px
      )
      this.echartOptionRight = this.buildOptionCurrent(
        _CHR,
        this.blerunningstate.maxRight,
        '#4A8ED3',
        px
      )
      this.echartOptionTime = this.buildOptionTime(this.dataObject.time, px)
    },
    isMan (sex) {
      return sex === 'man' ? true : false
    },
    buildOptionPower (value, px) {
      let color = '#2ED60F'
      if (value < 51) color = '#EDDB0F'
      if (value < 11) color = '#D00D0D'
      return {
        xAxis: {
          type: 'value',
          max: 100,
          show: false
        },
        yAxis: {
          type: 'category',
          show: false
        },
        grid: {
          x: 0,
          y: -8 * px,
          x2: 3 * px,
          y2: -8 * px
        },
        series: [
          {
            data: [value],
            type: 'bar',
            color: color,
            label: {
              show: true,
              position: [5 * px, 8 * px],
              color: '#fff',
              formatter: '{c}%'
            }
          }
        ]
      }
    },
    buildOptionCurrent (value, max, color, px) {
      return {
        xAxis: {
          type: 'value',
          max,
          show: false
        },
        yAxis: {
          type: 'category',
          show: false
        },
        grid: {
          x: 0,
          y: -8 * px,
          x2: 0,
          y2: -8 * px
        },
        series: [
          {
            data: [value],
            type: 'bar',
            color
          }
        ]
      }
    },
    buildOptionTime (data, px) {
      let obj = data.find(item => item.default === 'Y') || {}
      const str = `${obj.name}\n${obj.value}分钟`
      return {
        series: [
          {
            name: '治疗剩余时间',
            type: 'pie',
            radius: ['75%', '90%'],
            avoidLabelOverlap: false,
            label: {
              position: 'center',
              color: '#F39800',
              fontSize: 14 * px,
              fontWeight: 'bold',
              lineHeight: 24 * px,
              formatter: str
            },
            color: ['#1FC4F3', '#F39800'],
            data
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.device {
  width: calc(100% / 3);
  height: 50%;
  padding: 0 0 2rem 2rem;
  box-sizing: border-box;
  &:nth-child(n + 4) {
    padding-bottom: 0;
  }
  .wrap_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .header {
      padding: 1rem 1rem 0;
      overflow: hidden;
      .title {
        float: left;
        padding-left: var(--space-normal);
        border-left: 4px solid #ff1239;
        .index {
          font-size: var(--font-h2);
        }
      }
      .power {
        float: right;
        position: relative;
        top: 1rem;
        &::before {
          content: " ";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: url("~@/assets/images/power.png") no-repeat center/cover;
        }
      }
    }
    .contant {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0.5rem 1rem;
      .intro {
        height: 75%;
        flex: 1;
        display: flex;
        .base_info {
          max-width: 70%;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          .user {
            max-width: 100%;
            font-size: var(--font-h3);
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            .iconfont {
              margin: 0.25em;
              font-size: var(--font-h2);
              &.man {
                color: #f5733a;
              }
              &.woman {
                color: #fb68c2;
              }
            }
            &.smallSize {
              font-size: var(--font-h6);
              .iconfont {
                font-size: var(--font-h5);
              }
            }
          }
          .creatDate {
            width: 12rem;
            height: 2rem;
            font-size: var(--font-h5);
            text-align: center;
            line-height: 2rem;
            border: 2px solid #5468ff;
            border-radius: 1rem;
            background: rgba(84, 104, 255, 0.2);
          }
          .step {
            font-size: var(--font-h5);
            span:first-child {
              margin-right: var(--space-normal);
            }
          }
        }
      }
      .current {
        .left,
        .right {
          display: flex;
          margin: 0.75rem 0;
          font-size: var(--font-h3);
          .value {
            width: 6rem;
            padding-left: 1rem;
          }
        }
      }
      .body {
        width: 30%;
        position: relative;
        background: url("~@/assets/images/body.png") no-repeat center/auto 100%;
        .scan {
          width: 100%;
          height: 5px;
          position: absolute;
          bottom: 0;
          background: radial-gradient(closest-side at 50%, white, transparent);
        }
      }
    }
  }
}
</style>
<style>
.device .wrap_box .contant .scan {
  animation: scan 5s infinite alternate;
}
@keyframes scan {
  from {
    bottom: 0;
  }
  to {
    bottom: 100%;
  }
}
</style>
