<template>
  <div class="equipment">
    <div class="equipment_contant">
      <div class="statistics wrap_box">
        <div class="header">
          设备总体运行情况
        </div>
        <echart
          :config="echartConfig"
          :option="echartOption"
          v-if="echartOption"
        />
        <div class="resultGrid">
          <div
            class="box"
            v-for="item in totalData.equipmentData"
            :key="item.name"
          >
            <div class="name">
              {{ item.name }}
            </div>
            <div class="value">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="list"
        v-if="deviceData.length === 0"
      />
      <div
        class="list"
        v-if="deviceData.length > 0"
      >
        <device
          v-for="(item, index) in deviceData"
          :dataObject="item"
          :key="item.sn+ index"
        />
      </div>
    </div>
    <div
      class="pagination"
      v-if="deviceData.length > 0"
    >
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :currentPage="pagination.page"
        :pageSize="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.total"
      />
    </div>
  </div>
</template>
<script>
import echart from '@/libs/components/echart/echart'
import device from './_device'
import json from './json'
export default {
  name: 'EquipmentAA',
  components: {
    echart,
    device
  },
  props: {
    totalData: {
      type: Object,
      default: () => {
        return { equipmentData: [], equipmentChart: [] }
      }
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    inhospital: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      echartOption: '',
      echartConfig: {
        id: 'statistics',
        setStyle: { width: '20rem', height: '20rem', margin: '2.5rem auto' }
      },
      deviceData: [],
      timeInterval: () => { return },
      pagination: {
        page: 1,
        pageSize: 6,
        total: 6
      },
      autoPage: () => { return }
    }
  },
  mounted () {
    this.buildEchartOption()
  },
  beforeUnmount () {
    clearInterval(this.timeInterval)
    clearInterval(this.autoPage)
  },
  watch: {
    totalData: {
      handler () {
        this.buildEchartOption()
      },
      deep: true
    },
    inhospital: {
      handler () {
        this.setAutoPage()
      },
      deep: true
    }
  },
  async created () {
    this.setAutoPage()
    this.timeInterval = setInterval(this.getDeviceData, 1000)
  },
  methods: {
    setAutoPage () {
      if (this.inhospital) return clearInterval(this.autoPage)
      this.autoPage = setInterval(this.handleCurrentChange, 10000)
    },
    async getDeviceData () {
      if (this.$route.query.demo !== 'Y') {
        let res = await this.$root.libs.request(
          this.$root.libs.api.ssyypt.training_recipe_running_web.queryTrainRecipeRunning,
          {
            studioid: this.userInfo.id,
            jwt: this.userInfo.jwt,
            page: this.pagination.page,
            size: this.pagination.pageSize,
            endtimeIsNull: true
          },
          { headers: { jwt: this.userInfo.jwt } }
        )
        // 没有数据
        if (res.data.total === 0) return
        // 页码超过总页数
        this.pagination.pageSize = res.data.size
        this.pagination.total = res.data.total
        this.pagination.totalPage = Math.ceil(this.pagination.total / this.pagination.pageSize)
        if (this.pagination.page > this.pagination.totalPage) {
          this.pagination.page = this.pagination.totalPage || 2
          clearInterval(this.timeInterval)
          this.timeInterval = setInterval(this.getDeviceData, 1000)
          return
        }

        this.deviceData = this.dataFormat(res.data.records)
      }

      if (this.$route.query.demo === 'Y') {
        let res = JSON.parse(JSON.stringify(json.queryTrainRecipeRunning))
        this.pagination.pageSize = res.datas.size
        this.pagination.total = res.datas.totalElements
        this.pagination.totalPage = Math.ceil(this.pagination.total / this.pagination.pageSize) || 2

        this.deviceData = res.datas.content.slice(
          this.pagination.pageSize * (this.pagination.page - 1),
          this.pagination.pageSize * this.pagination.page
        )
        const random = this.deviceData[json.setRandom()]
        if (random) {
          random.time[0].value += json.setRandom()
          if (random.time[0].value < 5) random.time[0].value = 5
        }
      }
    },
    dataFormat (data) {
      let newDatas = data.map((item, index) => {
        let blerunningstate = item.blerunningstate ? JSON.parse(item.blerunningstate) : { bat: 0, intensityCHL: 0, intensityCHR: 0, userSet_intensityCHL: 0, userSet_intensityCHR: 0, remainingTime: 0, }
        item.id = index
        item.userSex = (item.usergender === '1' || item.usergender === '0' || !item.usergender) ? 'man' : 'woman'
        item.power = blerunningstate.bat
        item.maxLeft = Number(blerunningstate.userSet_intensityCHL) / 10
        item.maxRight = Number(blerunningstate.userSet_intensityCHR) / 10
        let remainingTime = blerunningstate.remainingTime, totalTime = blerunningstate.totalTime || 1800
        let treatedTime = totalTime - remainingTime
        remainingTime = Math.ceil(remainingTime / 60)
        treatedTime = Math.ceil(treatedTime / 60)
        item.time = [
          {
            value: treatedTime,
            name: '已治疗时间',
            default: 'N'
          },
          {
            value: remainingTime,
            name: '治疗剩余时间',
            default: 'Y'
          }
        ]
        return item
      })
      return newDatas
    },
    buildEchartOption () {
      const px = window.innerWidth / 120 / 16
      const active_color = new this.$root.echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
          { offset: 0, color: '#F0FF00' },
          { offset: 1, color: '#54FCFF' }
        ]
      )
      const equipmentChart = this.totalData.equipmentChart
      const option = equipmentChart.find(item => item.default === 'Y')
      const onlineNum = Math.round(
        (option.value /
          equipmentChart.reduce((n, m) => n.value * 1 + m.value * 1)) *
        100
      ) || 0
      const str = `{name|${option.name}}\n${onlineNum}%`

      this.echartOption = {
        series: [
          {
            name: '设备总体运行情况',
            type: 'pie',
            radius: ['60%', '90%'],
            avoidLabelOverlap: false,
            label: {
              position: 'center',
              color: '#fff',
              fontSize: 50 * px,
              fontWeight: 'bold',
              rich: {
                name: {
                  fontSize: 30 * px,
                  lineHeight: 60 * px
                }
              },
              formatter: str
            },
            color: [active_color, '#00609c'],
            data: equipmentChart
          }
        ]
      }
    },
    async handleCurrentChange (val) {
      this.pagination.page = val || (this.pagination.page + 1)
      if (this.pagination.page > this.pagination.totalPage) this.pagination.page = 1
      await this.getDeviceData()
    }
  }
}
</script>
<style lang="scss" scoped>
.equipment {
  flex: 1;
  display: flex;
  flex-direction: column;
  .equipment_contant {
    flex: 1;
    width: 100%;
    display: flex;
    .statistics {
      flex: 1;
      .header {
        height: 3.5rem;
        font-size: var(--font-h2);
        line-height: 3.5rem;
        text-align: center;
        color: #43dff8;
        background: url("~@/assets/images/statistics_title.png") no-repeat
          center/cover;
      }
      .resultGrid {
        width: 20rem;
        margin: 2.5rem auto;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        column-gap: 1px;
        row-gap: 1px;
        font-size: var(--font-h2);
        text-align: center;
        color: #49f5fd;
        .box {
          padding-top: 1rem;
          .value {
            font-size: 2em;
            letter-spacing: 0.05em;
            color: #fff;
          }
          &:nth-child(odd) {
            border: 1px solid #00a0e9;
            border-width: 1px 1px 0 0;
          }
          &:nth-child(even) {
            border-top: 1px solid #00a0e9;
          }
          &:nth-child(1) {
            border-top: 0;
          }
          &:nth-child(2) {
            border-top: 0;
          }
        }
      }
    }
    .list {
      flex: 3;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
<style scoped>
.pagination {
  padding-top: 1rem;
  text-align: center;
}
.pagination >>> .el-pagination {
  display: inline-block;
}
.pagination >>> button,
.pagination >>> .el-pager li,
.pagination >>> .el-pagination__jump {
  background-color: transparent !important;
  color: #0378d2 !important;
  border: 1px solid transparent;
  font-size: var(--font-h4);
}

.pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: transparent !important;
  color: #6dbbf7 !important;
  font-size: var(--font-h3);
}
.pagination >>> .el-pagination__jump .el-pagination__editor .el-input__inner {
  background-color: transparent !important;
  color: #0378d2 !important;
  border-color: inherit !important;
  font-size: var(--font-h4);
}
</style>
